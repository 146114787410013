.loginBtn {
	box-sizing: border-box;
	position: relative;
	width: 250px;
    margin: 0.2em;
    margin-bottom: 1rem !important;
	padding: 0 15px 0 20px;
	border: none;
	text-align: left;
	line-height: 34px;
	white-space: nowrap;
	border-radius: 0.2em;
	font-size: 16px;
	color: #fff !important;
}
.loginBtn:before {
	content: "";
	box-sizing: border-box;
	position: absolute;
	top: 0;
	left: 0;
	width: 34px;
	height: 100%;
}
.loginBtn:focus {
	outline: none;
}
.loginBtn:active {
	box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.1);
}

/* Google */
.loginBtn--google {
	/*font-family: "Roboto", Roboto, arial, sans-serif;*/
	background: #dd4b39 !important;
}
.loginBtn--google:before {
	border-right: #bb3f30 1px solid !important;
	background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_google.png")
		6px 6px no-repeat !important;
}
.loginBtn--google:hover,
.loginBtn--google:focus {
	background: #e74b37 !important;
}
