.main {
    display: block;
    width: 100%;
    height: 100%;
}

.topNav {
    top: 0;
    left: 0;
    position: fixed;
    width: 100%;
    border-width: 0px;
    border-bottom-width: 2px;
    border-style: solid;
    border-color: #f1f1f1;
    z-index: 502;
    max-height: 65px;
}

.topnavItem {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

.leftNavNoLogin {
    left: 0;
    position: fixed;
    top: 65px;
    height: 100%;
    height: 100%;
    width: 0px;
    z-index: 501;
    background-color: #e6eff3;
}

.leftNav {
    left: 0;
    position: fixed;
    top: 65px;
    height: 100%;
    height: 100%;
    max-width: 48px;
    z-index: 501;
    background-color: #256cd6;
}

.hidden {
    display:none;
}

.container {
    position: fixed;
    left: 48px;
    top: 65px;
    height: 100%;
    width: calc(100% - 48px);
}
.signincontainer {
    margin-left: -48px;
}

.mt {
    margin-top: 2rem;
}

.padd {
    padding: 10px;
}

.listItem {
    color: #fff;
    padding-left: 10px;
}
